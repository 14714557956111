import { parseInitData, retrieveLaunchParams } from '@telegram-apps/sdk';
import { useEffect, useState } from 'react';
import { getGameState } from '@/sdk/api/getGameData';
import { useRouter } from 'next/router';
import {ClipLoader} from "react-spinners";

declare global {
    interface Window {
        Telegram: any;
    }
}

export interface TelegramUser {
    id: string;
    first_name: string;
    last_name?: string;
    username?: string;
    language_code?: string;
}

export interface InitDataRaw {
    query_id?: string;
    user?: TelegramUser;
    auth_date?: number;
    hash?: string;
    [key: string]: any;
}

export default function MyApp() {
    const [initDataRaw, setInitDataRaw] = useState<string | null>(null);
    const [parsedData, setParsedData] = useState<InitDataRaw | null>(null);
    const router = useRouter();

    useEffect(() => {
        // Проверяем, что код выполняется на клиенте
        if (typeof window !== 'undefined') {
            const { initDataRaw: rawParams } = retrieveLaunchParams();
            setInitDataRaw(rawParams as string);

            // Проверяем, что Telegram WebApp объект доступен
            if (window.Telegram) {
                const tg = window.Telegram.WebApp;

                // Инициализируем Web App SDK
                tg.ready();

                // Получаем информацию о пользователе
                const user: TelegramUser | undefined = tg.initDataUnsafe?.user;
                if (user) {
                    alert(`User ID: ${user.id}`);
                    alert(`Username: ${user.username}`);
                    alert(`First Name: ${user.first_name}`);
                    alert(`Last Name: ${user.last_name}`);
                }
            } else {
                console.error('Telegram WebApp object not found. Make sure you are running inside Telegram.');
            }
        }
    }, []);

    useEffect(() => {
        if (initDataRaw) {
            // Парсинг initDataRaw в объект
            try {
                const parsed = parseInitData(initDataRaw) as InitDataRaw;
                setParsedData(parsed);
                console.log('Parsed Data:', parsed);
            } catch (error) {
                console.error('Failed to parse initDataRaw:', error);
            }
        }
    }, [initDataRaw]);

    useEffect(() => {
        if (parsedData) {
            getGameState(parsedData?.user?.id)
                .then((res) => {
                    console.log('ID', parsedData?.user?.id);
                    console.log(res, 'RESULT');
                    if(res.user.userInfo.id){
                        sessionStorage.setItem('auth',res.user.userInfo.id)
                        router.push('/home')
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 404) {
                        router.push('/register');
                    } else {
                        console.error('Failed to get game state:', error);
                    }
                });
        }
    }, [parsedData, router]);

    return (
        <div className={'w-screen h-screen welcome-screen p-4 flex flex-col gap-3 justify-center items-center'}>
            <img src={'/images/start_screen.png'}/>
            <ClipLoader size={40} color={'#FFF'}/>
            {/*<h1>Welcome to my Telegram Web App!</h1>*/}
            {/*{initDataRaw && <div>Init Data Raw: {JSON.stringify(initDataRaw)}</div>}*/}
            {/*{parsedData && <div>Parsed Data: {JSON.stringify(parsedData)}</div>}*/}
        </div>
    );
}